import { createWebHashHistory, createRouter } from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Patterns from '@/views/Patterns.vue';
import PointsList from '@/views/PointsList.vue';
import Profits from '@/views/Profits.vue';

import store from '@/store/index';
import { refresh } from '@/plugins/helpers';
import ChangePassword from '@/views/ChangePassword.vue';
import ChangePasswordEmail from '@/views/ChangePasswordEmail.vue';
import MessageView from '@/views/MessageView.vue';
import Analitics from '@/views/Analitics.vue';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/strona-glowna',
    name: 'Home',
    component: Home,
  },
  {
    path: '/lista-punktow',
    name: 'PointsList',
    component: PointsList,
  },
  {
    path: '/wzory-pism',
    name: 'Patterns',
    component: Patterns,
  },
  {
    path: '/porownanie',
    name: 'Profits',
    component: Profits,
  },
  {
    path: '/zmiana-hasla',
    name: 'ChangePasswordEmail',
    component: ChangePasswordEmail,
  },
  {
    path: '/zmiana-hasla/:hash',
    name: 'ChangePassword',
    component: ChangePassword,
    props: (route) => ({ hash: route.params.hash }),
  },
  {
    path: '/:msg',
    name: 'Message',
    component: MessageView,
    props: (route) => ({ msg: route.params.msg }),
  },
  {
    path: '/analityka',
    name: 'Analytics',
    component: Analitics,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'hash',
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['Login', 'ChangePasswordEmail', 'ChangePassword', 'Message'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = store.getters.accessToken;
  let refreshStatus = null;
  const url = process.env.VUE_APP_ADMIN_URL;

  if (store.getters.refreshToken) {
    refreshStatus = await refresh();
  }

  if ((authRequired && !loggedIn) || (refreshStatus && refreshStatus !== 200)) next({ name: 'Login' });
  else if (loggedIn && to.name === 'Login' && store.getters.isSuperuser) {
    next({ name: 'Home' });
  } else if (loggedIn && to.name !== 'Login' && store.getters.isSuperuser) {
    store.commit('deleteAccessToken');
    store.commit('deleteRefreshToken');
    window.location.href = url;
  } else next();
});

export default router;
