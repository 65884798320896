<template>
  <page>
    <hgroup class="mb-5 d-flex justify-content-between">
      <h1>Trwa pozyskiwanie danych ...</h1>
    </hgroup>

    <!-- Przykład użycia MButton, aby uniknąć błędu "komponent nieużywany" -->
    <m-button @click="changeShowMessage('Przykładowa wiadomość')">
      Pokaż wiadomość
    </m-button>

    <!-- Modal jest widoczny tylko, gdy showMessageModal jest true -->
    <message-modal
      v-if="showMessageModal"
      :message="modalMessage"
      @close="changeShowMessage"
    />
  </page>
</template>

<script>
import { ref } from 'vue';

import Page from '@/layouts/Page.vue';
import MButton from '@/components/common/MButton.vue';
import MessageModal from '@/components/modal/MessageModal.vue';

export default {
  name: 'Analytics', // Poprawiona nazwa komponentu
  components: {
    MessageModal,
    MButton,
    Page,
  },
  setup() {
    const showMessageModal = ref(false);
    const modalMessage = ref('');

    function changeShowMessage(e) {
      if (e) modalMessage.value = e;
      else modalMessage.value = '';
      showMessageModal.value = !showMessageModal.value;
    }

    return {
      changeShowMessage,
      modalMessage,
      showMessageModal,
    };
  },
};
</script>

<style scoped>
/* Dodanie przestrzeni dookoła tytułu */
h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}
</style>
