<template>
  <page title="Wzory">
    <download-files-box />
    <div class="user-documents-section">
      <h2>Moje dokumenty</h2>
      <table>
        <thead>
          <tr>
            <th>Nazwa Pliku</th>
            <th>Opis</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in documents" :key="document.id">
            <td>
              <a
                href="#"
                @click.prevent="downloadDocument(document.id, document.name)"
                class="file-link"
              >
                {{ document.name }}
              </a>
            </td>
            <td>{{ document.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </page>
</template>

<script>
import Page from '@/layouts/Page.vue';
import DownloadFilesBox from '@/layouts/DownloadFilesBox.vue';
import axios from 'axios';

export default {
  name: 'Patterns',
  components: { DownloadFilesBox, Page },
  data() {
    return {
      documents: [],
    };
  },
  methods: {
    async fetchDocuments() {
      try {
        const response = await axios.get('/api/documents/');
        this.documents = response.data;
      } catch (error) {
        console.error('Błąd podczas pobierania dokumentów:', error);
      }
    },
    async downloadDocument(documentId, documentName) {
      try {
        const response = await axios.get(`/api/documents/${documentId}/download/`, {
          responseType: 'blob',
        });
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = documentName;
        link.click();
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Błąd podczas pobierania dokumentu:', error);
      }
    },
  },
  mounted() {
    this.fetchDocuments();
  },
};
</script>

<style scoped>
.user-documents-section {
  margin-top: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th,
td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

th {
  background-color: #f9f9f9;
}

.file-link {
  color: #007bff;
  text-decoration: none;
  cursor: pointer;
}

.file-link:hover {
  text-decoration: underline;
}
</style>
