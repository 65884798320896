<template>
  <header class="navbar navbar-dark bg-dark fixed-top navbar-expand-lg">
    <div class="container">
      <router-link :to="'/strona-glowna'" class="navbar-brand">
        <img class="logo" :src="require('@/assets/logo.png')" alt="Voltra">
      </router-link>
      <ul class="navbar-nav">
        <li
          class="nav-item"
          v-for="link in links"
          :key="link.to"
        >
          <router-link :to="link.to" class="nav-link">{{ link.name }}</router-link>
        </li>
        <li class="nav-item">
          <router-link :to="'/'" @click="logout" class="nav-link">Wyloguj</router-link>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
import { logout } from '@/plugins/auth';

export default {
  name: 'MHeader',
  setup() {
    const links = [
      {
        to: '/analityka',
        name: 'Analityka',
      },
      {
        to: '/lista-punktow',
        name: 'Lista punktów',
      },
      {
        to: '/wzory-pism',
        name: 'Wzory pism',
      },
      {
        to: '/porownanie',
        name: 'Ile zyskujesz',
      },
    ];

    return {
      links,
      logout,
    };
  },
};
</script>

<style scoped>
  .logo {
    height: 20px;
    margin-bottom: -4px;
  }
</style>
