<template>
  <page>
    <hgroup class="mb-5 d-flex justify-content-between">
      <h1>Lista punktów</h1>
      <div>
        <m-button
          class="btn btn-success btn-lg me-2"
          name="Zgłoś nowy punkt"
          emitName="addPoint"
          icon="bi-plus-circle"
          @addPointButton="changeShowModal"
        />
        <m-button
          class="btn btn-success btn-lg me-2"
          name="Pobierz raport"
          emitName="downloadReport"
           icon="bi-plus-circle"
          @click="downloadReport"
        />
      </div>
    </hgroup>
    <points-table
      @showMessage="changeShowMessage"
    />
    <teleport
      v-if="showAddPointModal"
      to="#app"
    >
      <add-point-modal
        @closeModalButton="changeShowModal"
        @showMessage="changeShowMessage"
      />
    </teleport>
    <teleport
      v-if="showMessageModal"
      to="#app"
    >
      <message-modal
        :message="modalMessage"
        @showMessage="changeShowMessage"
      />
    </teleport>
  </page>
</template>

<script>

import { ref } from 'vue';
import Page from '@/layouts/Page.vue';
import PointsTable from '@/components/table/PointsTable.vue';
import MButton from '@/components/common/MButton.vue';
import AddPointModal from '@/components/modal/AddPointModal.vue';
import MessageModal from '@/components/modal/MessageModal.vue';
import axios from 'axios';
import { urlBuilder } from '@/plugins/helpers';

export default {
  name: 'PointsList',
  components: {
    MessageModal,
    AddPointModal,
    MButton,
    PointsTable,
    Page,
  },
  setup() {
    const showAddPointModal = ref(false);
    const showMessageModal = ref(false);
    const modalMessage = ref('');
    function changeShowModal() {
      showAddPointModal.value = !showAddPointModal.value;
    }
    function changeShowMessage(e) {
      if (e) modalMessage.value = e;
      else modalMessage.value = '';
      showMessageModal.value = !showMessageModal.value;
    }
    async function downloadReport() {
      try {
        const response = await axios.get(urlBuilder('/points/report'), { responseType: 'blob' });

        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'raport.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        await axios.get(urlBuilder('/user_info/'));
      }
    }

    return {
      changeShowModal,
      changeShowMessage,
      modalMessage,
      showMessageModal,
      showAddPointModal,
      downloadReport,
    };
  },
};
</script>

<style scoped>
</style>
