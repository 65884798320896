<template>
  <m-header />
  <div class="voltra-bg w-100 p-5 mt-5 text-center">
    <div class="container pb-5 pt-5 flex-shrink-0">
      <div class="display-4 fw-bold">Energia najlepszych rozwiązań</div>
      <p class="lead fw-bold text-uppercase">Sukcesy mierzone oszczędnościami</p>
    </div>
  </div>
  <div class="container mt-5 pt-5 flex-shrink-0">
    <h1 v-if="title" class="mb-5">{{ title }}</h1>

    <hgroup class="mb-2 d-flex justify-content-between">
      <h1 class="mb p-0">Witaj {{ user.first_name }}!</h1>
    </hgroup>
    <hr>
    <div class="row mt-5">
      <div class="col-md-6">
        <dl class="row">
          <dt class="col-sm-5">Zalogowany jako</dt>
          <dd class="col-sm-7">{{ user.first_name }} {{ user.last_name }}</dd>
          <dt class="col-sm-5">Email</dt>
          <dd class="col-sm-7">{{ user.email }}</dd>
          <dt class="col-sm-5">Twój link do grupy zakupowej:</dt>
          <dd class="col-sm-7">
            <a v-if="user.buying_group_link" :href="user.buying_group_link" target="_blank">
              {{ user.buying_group_link }}
            </a>
            <span v-else>Brak przypisanego linku</span>
          </dd>
          <dt class="col-sm-5">Obsługiwane jednostki</dt>
          <dd class="col-sm-7">
            <template v-for="(division, index) in user.divisions" :key="division">
              <template v-if="index !== 0">, </template>{{ division }}
            </template>
          </dd>
        </dl>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-4 mb-4">
        <router-link :to="'/lista-punktow'" class="nav-link">
          <div class="btn-hero btn-points text-center">
            <h1 class="m-0 p-0">Punkty Poboru Energii</h1>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 mb-4">
        <router-link :to="'/porownanie'" class="nav-link">
          <div class="btn-hero btn-savings text-center">
            <h1 class="m-0 p-0">Analizy</h1>
          </div>
        </router-link>
      </div>
      <div class="col-lg-4 mb-4">
        <router-link :to="'/wzory-pism'" class="nav-link">
          <div class="btn-hero btn-templates text-center">
            <h1 class="m-0 p-5">Wzory dokumentów</h1>
          </div>
        </router-link>
      </div>
    </div>
  </div>

  <m-footer />
</template>

<script>
import MHeader from '@/components/navigation/MHeader.vue';
import MFooter from '@/components/navigation/MFooter.vue';
import axios from 'axios';
import { urlBuilder } from '@/plugins/helpers';
import { onMounted, ref } from 'vue';

export default {
  name: 'Home',
  components: {
    MHeader,
    MFooter,
  },
  setup() {
    const user = ref({
      first_name: '',
      last_name: '',
      email: '',
      divisions: [],
      buying_group_link: null,
    });

    async function getUser() {
      try {
        const { data } = await axios.get(urlBuilder('/user_info/'));
        user.value = { ...user.value, ...data }; // Aktualizuj użytkownika
      } catch (error) {
        console.error('Błąd podczas pobierania danych użytkownika:', error);
      }
    }

    async function getBuyingGroupLink() {
      try {
        const { data } = await axios.get(urlBuilder('/divisions/link/'));
        user.value.buying_group_link = data.link;
      } catch (error) {
        console.error('Błąd podczas pobierania linku do grupy zakupowej:', error);
        user.value.buying_group_link = null;
      }
    }

    onMounted(async () => {
      await Promise.all([getUser(), getBuyingGroupLink()]);
    });

    return {
      user,
    };
  },
};
</script>

<style scoped>
  .btn-hero {
    background-color: rgb(255, 207, 0);
    color: #000;
    border-radius: 13px;
    display: flex;
    height: 270px;
    width: 100%;
    align-items: center;
    justify-content: center;
    transition: 0.6s all, 0.2s color;
    cursor: pointer;
    position: relative;
    scale: 1;
    overflow: hidden;
  }

  .btn-hero h1 {
    position: relative;
    font-weight: 700;
  }

  .btn-hero:hover {
    box-shadow:
    0 1.8px 2.2px rgba(0, 0, 0, 0.034),
    0 4.7px 5.3px rgba(0, 0, 0, 0.048),
    0 8.5px 10px rgba(0, 0, 0, 0.06),
    0 16.3px 17.9px rgba(0, 0, 0, 0.072);
    scale: 1.05;
  }

  .btn-hero:hover:before {
    opacity: 0.1;
  }

  .btn-hero:before {
    content: "";
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.25;
    filter: grayscale(100%);
    transition: all 0.7s;
  }

  .btn-points:before {
    background-image: url("~@/assets/points.jpeg");
  }

  .btn-savings:before {
    background-image: url("~@/assets/analysis.jpeg");
    background-position: center bottom;
  }

  .btn-templates:before {
    background-image: url("~@/assets/documents.jpg");
  }

  .voltra-bg {
    background-image: url("~@/assets/header_bg.jpeg");
    background-size: cover;
    color: white;
    background-position: center center;
    text-shadow: 0 3px 6px #000;
  }
</style>
